<template>
    <div class="root">
        <StackRouterHeaderBar :showTitle="showHeaderTitle" :title="$translate('PROFILE_GUIDE_SECTION_TITLE_1')" />
        <main class="main">
            <ProfileGuideSection
                :key="key"
                :title="$translate(section.title)"
                :sectionItems="section.items"
                v-for="(section, key) in sections"
            />
        </main>
    </div>
</template>
<script>
import ProfileGuideSection from './components/ProfileGuideSection.vue'

export default {
    name: 'ProfileGuidePage',
    components: {
        ProfileGuideSection,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        sections() {
            return [
                {
                    title: 'PROFILE_GUIDE_SECTION_TITLE_1',
                    items: [
                        {
                            itemTitle: '얼굴이 분명하게 나온 사진',
                            tip: '자연스러운 일상, 활동 사진을 추천합니다. 👏',
                            photos: [
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide01.png'),
                                },
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide02.png'),
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'PROFILE_GUIDE_SECTION_TITLE_2',
                    items: [
                        {
                            itemTitle: '마스크로 얼굴을 가린 사진',
                            photos: [
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide03.png'),
                                },
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide04.png'),
                                },
                            ],
                        },
                        {
                            itemTitle: '어둡거나 화질이 안 좋은 사진',
                            photos: [
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide05.png'),
                                },
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide06.png'),
                                },
                            ],
                        },
                        {
                            itemTitle: '동물, 사물, 배경 사진',
                            photos: [
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide07.png'),
                                },
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide08.png'),
                                },
                            ],
                        },
                        {
                            itemTitle: '본인 식별이 불가능한 사진',
                            photos: [
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide09.png'),
                                },
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide10.png'),
                                },
                            ],
                        },
                        {
                            itemTitle: '과도한 스티커, 필터를 사용한 사진',
                            photos: [
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide11.png'),
                                },
                                {
                                    src: require('@/assets/images/my-page/ProfileGuide/guide12.png'),
                                },
                            ],
                        },
                    ],
                },
            ]
        },
    },

    mounted() {
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
$header-height: 52px;

.my-page {
    width: 100%;
    height: 100%;
    background: white;
}

.main {
    overflow-y: scroll;
    transition: height 0s;
    height: calc(100vh - #{$header-height});
    padding: 16px;
}
</style>
