<template>
    <div class="section">
        <span class="section-title" v-html="title" />
        <div class="section-item" :key="key" v-for="(item, key) in sectionItems">
            <span class="section-item-title">{{ item.itemTitle }}</span>
            <div class="section-item-box">
                <div class="photo-item" :key="key2" v-for="(photo, key2) in item.photos">
                    <img class="photo-image" width="100%" height="100%" :src="photo.src" />
                </div>
            </div>
            <div class="section-item-tip" v-if="item.tip != undefined">
                <div class="tip-badge">tip!</div>
                {{ item.tip }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileGuideSection',
    props: ['title', 'sectionItems'],
}
</script>

<style scoped lang="scss">
div.section {
    &:not(:first-child) {
        margin-top: 50px;

        &:last-child {
            margin-bottom: 50px;
        }
    }

    span.section-title {
        color: black;
        letter-spacing: -0.48px;
        font-size: 24px;
        @include f-medium;
    }

    div.section-item {
        margin-top: 32px;

        span.section-item-title {
            color: $grey-09;
            font-size: 16px;
            letter-spacing: -0.21px;

            @include f-regular;
        }

        div.section-item-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 12px;

            div.photo-item {
                width: calc(100% / 2 - 6px);
                height: calc((100vw - 32px) / 2 - 6px);

                img.photo-image {
                    border-radius: 12px;
                    box-shadow: 0 0 0 1px $grey-02 inset;
                }
            }
        }

        div.section-item-tip {
            color: #8a74ff;
            display: flex;
            justify-content: center;
            font-size: 13px;
            margin-top: 12px;

            @include f-medium;

            div.tip-badge {
                box-shadow: 0 0 0 1px currentColor inset;
                border-radius: 8px;
                font-size: 12px;
                padding: 0 8px;
                margin-right: 6px;
            }
        }
    }
}
</style>
